import Hero, { HeroProps } from 'blocks/hero/hero';
import ShareBar from 'components/share/shareBar';
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import './post.template.scss';
import imagesLoaded from 'imagesloaded';
import { handlePageLoaded } from 'components/trans/transLink';
import PostInner, { PostProps } from './post.inner';
import { TransLink } from 'components/trans/transLink';
import classNames from 'classnames';
import SEO from 'components/seo/seo';

interface Props {
  pageContext: pageContextProps;
  data: {
    post: PostProps;
  };
}

const PostTemplate: React.FC<Props> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [heroData, setHeroData] = useState<HeroProps>();

  useEffect(() => {
    const main = mainRef.current;
    !!main && imagesLoaded(document.querySelectorAll('[data-preload]'), handlePageLoaded);
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    const heroes = props.data.post.blocks.filter((item) => item.name === 'acf/hero');
    if (!!heroes.length) {
      setHeroData(heroes[0].hero);
    }
  }, [props.data.post]);

  return (
    <div className={classNames('post', { 'is-loading-complete': isLoaded, 'no-hero': !heroData })} id={props.pageContext.id} ref={mainRef}>
      <SEO {...props.pageContext.seo} />
      {!!heroData && <Hero {...heroData} />}
      <PostInner {...props.data.post} parent={{ title: 'Publikation' }} pageContext={props.pageContext} />
      <ShareBar />
      <div className="post-back">
        <TransLink to={'/publikationen'} className="link-text">
          Zur Übersicht
        </TransLink>
      </div>
    </div>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!) {
    post: wpPost(id: { eq: $id }) {
      author {
        node {
          firstName
          lastName
        }
      }
      blocks {
        name
        order
        ...AnchorLinksBlock
        ...AuthorLineBlock
        ...ContactBlock
        ...DataWrapperBlock
        ...FormBlock
        ...ForschungDetailBlock
        ...GalleryBlock
        ...GraphBlock
        ...HeadingBlock
        ...HeroBlock
        ...HeroSmallBlock
        ...ImageBlock
        ...InfoBoxBlock
        ...LinksBlock
        ...ListBlock
        ...MultiColumnTextBlock
        ...PageFooterTextBlock
        ...ParagraphBlock
        ...PartnerBlock
        ...PostWithTagBlock
        ...PullQuoteBlock
        ...ReadMoreBlock
        ...SidTeasersBlock
        ...SourceBlock
        ...TeamGridBlock
        ...TeamGridSimpleBlock
        ...TopicsBlock
        ...ValuesBlock
        ...VideoIframeBlock
        ...ShareButtonsBlock
      }
      date(formatString: "YYYY-MM-DD")
      terms {
        nodes {
          name
          taxonomyName
        }
      }
      title
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PostTemplate;

import { HeroProps } from 'blocks/hero/hero';
import Blocks from 'components/blocks/blocks';
import moment from 'moment';
import React from 'react';
import { pageContextProps } from 'utils/props.utils';

export interface PostProps {
  author: {
    node: {
      firstName: string;
      lastName: string;
    };
  };
  hero?: HeroProps;
  blocks: any[];
  date: string;
  title: string;
  terms?: {
    nodes: {
      name: string;
      taxonomyName: string;
    }[];
  };
  postNews: {
    newsSubline: string;
  };
  parent: {
    title: string;
  };
  pageContext: pageContextProps
}

interface Props extends PostProps {}

const PostInner: React.FC<Props> = (props) => {
  return (
    <section className="section">
      <div className="section-header">
        <h5 className="section-header__title">{props.parent.title}</h5>
      </div>
      <div className="section-content post-content">
        <ul className="post-meta">
          {!!props.terms && (
            <li className="post-meta__terms">
              {props.terms.nodes.map((item, key) => {
                return item.taxonomyName === 'category' && <span key={key}>{item.name}</span>;
              })}
            </li>
          )}
          {!!props.postNews?.newsSubline && <li>{props.postNews?.newsSubline.replaceAll('<p>', '').replaceAll('</p>', '')}</li>}
          <li>{`${props.author.node.firstName} ${props.author.node.lastName}`}</li>
          <li>{moment(props.date).locale('de').format('DD. MMMM YYYY')}</li>
        </ul>
        <h1>{props.title}</h1>
        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <h2>Headline 2</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <h3>Headline 3</h3>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <h4>Headline 4</h4>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <h5>Headline 5</h5>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <h6>Headline 6</h6>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> */}
        <Blocks blocks={props.blocks} glossary={props.pageContext.glossary} />
      </div>
    </section>
  );
};

export default PostInner;
